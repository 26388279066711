import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
// import "./AppLayout.css";
import { auth, logout } from "../../store/firebase";
import { getCacheClub, getCacheTeam } from "../../hooks/useLocalStorage";
import { useDispatch } from "react-redux";

const AppLayout = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, loading, error] = useAuthState(auth);
  const [active, setActive] = useState(0);

  useEffect(() => {
    if (!user || error) {
      return navigate("/");
    }
    if (user) {
      getData().then(({ club, team }) => {
        if (user && club && team?.id) {
          return navigate("/home");
        }
        if (user && club && !team?.id) {
          return navigate("/teamSelection");
        }
      });
    }
  }, [user, loading]);

  async function getData() {
    const club = await getCacheClub();
    const team = await getCacheTeam();
    return { club, team };
  }

  const signout = () => {
    dispatch(logout());
    return navigate("/");
  };
  const onLinkPress = (index) => {
    setActive(index);
  };

  if (loading) {
    return <div className="flex-center" />;
  }

  return user ? (
    <div className="layout">
      <div className="header">
        <div className="logout">
          <span onClick={signout} className="logout-btn">
            Logout
          </span>
        </div>
      </div>

      {children}
    </div>
  ) : (
    <div className="layout">{children}</div>
  );
};

export default AppLayout;
