import { BrowserRouter } from "react-router-dom";
import "./sass/main.scss";
import AppLayout from "./components/AppLayout";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./store/firebase";
import PrivateRoutes from "./routes/privateRoutes";
import PublicRoutes from "./routes/publicRoutes";

function App() {
  const [user] = useAuthState(auth);
  return (
    <BrowserRouter>
      <AppLayout>{user ? <PrivateRoutes /> : <PublicRoutes />}</AppLayout>
    </BrowserRouter>
  );
}

export default App;
