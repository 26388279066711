import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

import n11_login_logo from "../../assets/images/n11-login-logo.png";
import Dropdown from "../../components/common/Dropdown";
import { cacheTeam } from "../../hooks/useLocalStorage";
import { auth, getClubByUser } from "../../store/firebase";

const TeamSelection = () => {
  const navigate = useNavigate();
  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState("");
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    getClubData().then((res) => {
      setTeams(res);
    });
  }, [user, loading, error]);

  async function getClubData() {
    return getClubByUser(user.uid);
  }
  async function handleSubmit(e) {
    e.preventDefault();
    if (team.id === null) {
      return;
    }
    const teamSelected = teams.find((t) => t.id === team);
    cacheTeam(teamSelected);
    navigate("/home");
  }

  return (
    <div className="login-wrapper">
      <div className="login-container">
        <div className="logo-container">
          <img src={n11_login_logo} />
        </div>
        <form className="form-container">
          <div className="form-welcome-container">
            <p className="heading-secondary">Choose Team</p>
          </div>
          <div className="form-inputs-container">
            <Dropdown
              placeholder="Choose a team"
              value={team}
              options={
                teams.length > 0
                  ? teams.map((t) => {
                      return { id: t.id, name: t.data.name };
                    })
                  : []
              }
              handleChange={(e) => setTeam(e)}
            />
            <div className="login-button-wrapper">
              <div className="login-button-container">
                <button
                  type="submit"
                  className={`login-button`}
                  onClick={handleSubmit}
                  disabled={!team}
                >
                  <span className="login-text">Next</span>
                  <span className="triangle--s triangle--right" />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TeamSelection;
