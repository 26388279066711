import {
  FETCHING_EVENTS,
  FETCHED_EVENTS_SUCCESS,
  FETCHED_EVENTS_FAILURE,
} from "../constants";

const initialState = {
  data: [],
  isFetching: false,
  fetched: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_EVENTS:
      return { data: [], isFetching: true, fetched: false };
    case FETCHED_EVENTS_SUCCESS:
      return { data: action.payload, isFetching: false, fetched: true };
    case FETCHED_EVENTS_FAILURE:
      return {
        data: [],
        isFetching: false,
        fetched: true,
        error: action.payload,
      };
    default:
      return state;
  }
};
