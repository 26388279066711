import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "../reducers";
import logger from "redux-logger";

const middlewares = [thunk];
// let middlewares = [thunk, logger];
const middlewareEnhancer = applyMiddleware(...middlewares);
const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

const configureStore = () =>
  createStore(rootReducer, undefined, composedEnhancers);

export default configureStore;
