export const LOG_OUT = "LOG_OUT";

export const FETCHING_USER = "FETCHING_USER";
export const FETCHED_USER_SUCCESS = "FETCHED_USER_SUCCESS";
export const FETCHED_USER_FAILURE = "FETCHED_USER_FAILURE";

export const FETCHING_EVENTS = "FETCHING_EVENTS";
export const FETCHED_EVENTS_SUCCESS = "FETCHED_EVENTS_SUCCESS";
export const FETCHED_EVENTS_FAILURE = "FETCHED_EVENTS_FAILURE";

export const FETCHING_PLAYERS = "FETCHING_PLAYERS";
export const FETCHED_PLAYERS_SUCCESS = "FETCHED_PLAYERS_SUCCESS";
export const FETCHED_PLAYERS_FAILURE = "FETCHED_PLAYERS_FAILURE";
