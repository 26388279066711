import React, { useEffect, useState, useRef } from "react";
import { generateSizeClass } from "../../../helpers/sassHelpers";

import { ReactComponent as ChevronRight } from "../../../assets/svg/chevron-right.svg";

const data = [
  { id: 0, label: "Istanbul, TR (AHL)" },
  { id: 1, label: "Paris, FR (CDG)" },
];

// const Dropdown = ({
//   name,
//   value,
//   initialOption,
//   options,
//   handleChange,
//   disabled = false,
//   size,
//   placeholder = "Select Option",
//   closeOnClick = false,
// }) => {
//   const [isOpen, setOpen] = useState(false);

//   const myRef = useRef(null);
//   // useEffect(() => {
//   //   if (isOpen) {
//   //     scrollToRef();
//   //   }
//   // }, [isOpen]);

//   // const scrollToRef = (ref) => {
//   //   let elem = document.getElementsByClassName("dropdown-item__selected");
//   //   if (elem && elem.length > 0) {
//   //     myRef.current.scrollTo({
//   //       top: elem[0].offsetTop,
//   //       behavior: "smooth",
//   //     });
//   //   }
//   // };
//   const toggleDropdown = () => {
//     setOpen(!isOpen);
//   };

//   return (
//     <div className="dropdown">
//       <div className="dropdown-header" onClick={() => toggleDropdown()}>
//         <p className="paragraph__l ">
//           {value ? options.find((item) => item.id == value)?.name : placeholder}
//         </p>
//         <span className={`center-items abs__r ${isOpen ? "rotate__90" : ""}`}>
//           <ChevronRight height="20px" width={"30px"} />
//         </span>
//       </div>
//       {isOpen && (
//         <div
//           ref={myRef}
//           className={`dropdown-body ${isOpen ? "open" : ""}`}
//           id="dropdown-body"
//         >
//           {options.map((item) => (
//             <div
//               className={`dropdown-item ${
//                 value == item.id ? "dropdown-item__selected" : ""
//               }`}
//               onClick={() => {
//                 handleChange(item.id);
//                 toggleDropdown();
//               }}
//               id={item.id}
//               key={item.id}
//             >
//               {item.name}
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

const Dropdown = ({
  name,
  value,
  initialOption,
  options,
  handleChange,
  disabled,
  size,
}) => {
  return (
    // <div className="input-wrapper">
    <div className="dropdown-wrapper">
      <select
        name={name}
        onChange={(e) => handleChange(e.target.value)}
        className={`dropdown-component ${
          size ? `dropdown-component__${size}` : ""
        }`}
        value={value}
        defaultValue=""
        disabled={disabled}
      >
        <option value={initialOption || ""} disabled>
          {initialOption || "--Please choose an option--"}
        </option>
        {options &&
          options.length > 0 &&
          options.map((o) => {
            return (
              <option value={o.id} key={o.id}>
                {o.name}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export default Dropdown;
