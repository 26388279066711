import React, { useEffect, useState } from "react";

import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import Input from "../../components/common/Input";
import { auth, loginWithEmailAndPassword } from "../../store/firebase";
import n11_login_logo from "../../assets/images/n11-login-logo.png";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);

  const navigate = useNavigate();
  useEffect(() => {
    if (loading) return;

    if (error) return alert(`ERROR: ${error}`);

    if (user) {
      return navigate("/teamSelection");
    }
  }, [user, loading]);

  async function handleSubmit(e) {
    e.preventDefault();
    if (email && password) {
      await loginWithEmailAndPassword(email, password);
    }
  }

  return (
    <div className="login-wrapper">
      <div className="login-container">
        <div className="logo-container">
          <img src={n11_login_logo} />
        </div>
        <form className="form-container">
          <div className="form-welcome-container">
            <p className="heading-secondary">Welcome</p>
          </div>
          <div className="form-inputs-container">
            <p className="paragraph__l paragraph__grey paragraph__center">
              Enter your email and password to login
            </p>
            <Input
              name={"email"}
              onChange={(e) => setEmail(e.value)}
              placeholder="Email"
              value={email}
            />
            <Input
              name={"password"}
              onChange={(e) => setPassword(e.value)}
              placeholder="Password"
              value={password}
            />

            <div className="login-button-wrapper">
              <div className="login-button-container">
                <button
                  type="submit"
                  className={`login-button ${
                    loading && "login-button--loading"
                  }`}
                  onClick={handleSubmit}
                >
                  <span className="login-text">Login</span>
                  <span className="triangle--s triangle--right" />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
export default Login;
