export const reportTypes = [
  { name: "Weekly Report", id: "Weekly Report" },
  { name: "Session", id: "Session" },
];

export const weeklyDataSetType = [
  { name: "Events", id: "Events" },
  { name: "Team", id: "Team" },
  { name: "Player", id: "Player" },
];

export const sessionDataSetType = [
  { name: "Team", id: "Team" },
  { name: "Player", id: "Player" },
];
