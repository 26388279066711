import { getAdditionalUserInfo } from "firebase/auth";
import {
  dayIndex,
  formatDateAndTime,
  formatFirebaseDateAndTime,
  yearDaysArray,
} from "../helpers/dateTimeHelpers";

const extractPositionOffset = (position) =>
  `${
    position > 0
      ? `+${position} days after match`
      : `${position}days before match`
  }`;

export function roundNumber(num) {
  return num < 1
    ? Math.round((num + Number.EPSILON) * 100) / 100
    : Math.round(num);
}

export function calculateAvg(teamData) {
  const { data, amount } = teamData;
  if (amount === 0) {
    return 0;
  }
  const avg =
    data.filter((d) => d !== undefined).reduce((a, b) => a + b, 0) / amount;
  return Math.round(avg);
}

export function calculateTeamAvgStats({
  allEvents = { data: [], isFetching: false, fetched: false, error: null },
  selectedWeek,
  teamName,
  players,
  selectedEvent,
  selectedPlayer,
}) {
  let fullYear = yearDaysArray;
  allEvents?.data?.map((event) => {
    let eventTime = new Date(event.event.date?.seconds * 1000);
    let eventIndex = dayIndex(eventTime);
    fullYear[eventIndex] = event;
  });

  let fullYearClone = [...fullYear];

  fullYear.map((event, index) => {
    if (event && event.event.type === "match") {
      let pass = true;
      for (let i = 1; i < 3; i++) {
        if (fullYear[index + i] && fullYear[index + i].event.type === "match") {
          pass = false;
        }
        if (
          pass &&
          fullYear[index + i] &&
          fullYear[index + i].event.type !== "match"
        ) {
          fullYearClone[index + i].position = i;
          fullYearClone[index + i].index = index;
        }
      }
      pass = true;
      for (let i = -1; i > -5; i--) {
        if (fullYear[index + i] && fullYear[index + i].event.type === "match") {
          pass = false;
        }
        if (
          pass &&
          fullYear[index + i] &&
          fullYear[index + i].event.type !== "match"
        ) {
          fullYearClone[index + i].position = i;
          fullYearClone[index + i].index = index;
        }
      }
    }
  });

  let data = fullYear.filter((e) => e !== undefined);

  let teamDataStats =
    data
      .filter((event) => event.stats?.team?.fullGame?.stats)
      .map((event) => {
        let position = `${
          event.position
            ? `${
                event.position > 0
                  ? `+${event.position} `
                  : `${event.position} `
              }`
            : ""
        }`;
        let date = formatFirebaseDateAndTime(event.event.date?.seconds);
        let type = event.event.type === "training" ? "Training" : "Match";
        let versus =
          event.event.type === "match" ? `vs ${event.event.versus}` : "";
        let displayName = `${position}${type} ${date} ${versus}`;
        let reformatEvent = { ...event, displayName };
        return reformatEvent;
      }) || [];

  const weekTeamDataStats = teamDataStats.filter((event) => {
    let eventTime = event.event.date?.seconds * 1000;
    if (
      selectedWeek.startOfWeek <= eventTime &&
      eventTime <= selectedWeek.endOfWeek
    ) {
      return event;
    }
  });
  const allWeekTeamDataStats = data.filter((event) => {
    let eventTime = event.event.date?.seconds * 1000;
    if (
      selectedWeek.startOfWeek <= eventTime &&
      eventTime <= selectedWeek.endOfWeek
    ) {
      return event;
    }
  });

  const amount = data.length;
  const amountStats = teamDataStats.length;
  const amountWeeklyStats = weekTeamDataStats.length || 1;

  if (amount === 0) {
    return false;
  }

  // AVERAGE CALCULATIONS

  // TOTAL

  const avgTeamDistanceTotal = roundNumber(
    teamDataStats
      .map((event) => event.stats.team.fullGame.stats.Distance.distance)
      .reduce((a, b) => a + b, 0) / amountStats
  );
  const avgTeamIntensityTotal = roundNumber(
    teamDataStats
      .map((event) => event.stats.team.fullGame.stats.Intensity?.amount)
      .reduce((a, b) => a + b, 0) / amountStats
  );
  const avgTeamSprintsTotal = roundNumber(
    teamDataStats
      .map(
        (event) => event.stats.team.fullGame.stats.Normal?.sprinting?.distance
      )
      .reduce((a, b) => a + b, 0) / amountStats
  );

  const avgTeamActiveTimeTotal = roundNumber(
    teamDataStats
      .map((event) =>
        event.event.status && event.event.status?.totalTime
          ? event.event.status?.totalTime
          : 0
      )
      .reduce((a, b) => a + b, 0) /
      amountStats /
      60000
  );

  // WEEKLY

  const avgTeamDistanceWeek = roundNumber(
    weekTeamDataStats
      .map((event) => event.stats.team.fullGame.stats.Distance.distance)
      .reduce((a, b) => a + b, 0) / amountWeeklyStats
  );
  const avgTeamIntensityWeek = roundNumber(
    weekTeamDataStats
      .map((event) => event.stats.team.fullGame.stats.Intensity?.amount)
      .reduce((a, b) => a + b, 0) / amountWeeklyStats
  );
  const avgTeamSprintsWeek = roundNumber(
    weekTeamDataStats
      .map(
        (event) => event.stats.team.fullGame.stats.Normal?.sprinting?.distance
      )
      .reduce((a, b) => a + b, 0) / amountWeeklyStats
  );
  const avgTeamHIRunningWeek = roundNumber(
    weekTeamDataStats
      .map(
        (event) => event.stats.team.fullGame.stats.Normal?.hiRunning?.distance
      )
      .reduce((a, b) => a + b, 0) / amountWeeklyStats
  );

  const avgTeamActiveTimeWeek = roundNumber(
    weekTeamDataStats
      .map((event) =>
        event.event.status && event.event.status?.totalTime
          ? event.event.status?.totalTime
          : 0
      )
      .reduce((a, b) => a + b, 0) /
      amountWeeklyStats /
      60000
  );

  // WEEK CHART DATA
  const weeklyData = [0, 0, 0, 0, 0, 0, 0];
  const emptyWeeklyArray = [null, null, null, null, null, null, null];
  const weeklyDescriptionsArray = [
    { name: "Off Pitch", id: 0, position: null },
    { name: "Off Pitch", id: 1, position: null },
    { name: "Off Pitch", id: 2, position: null },
    { name: "Off Pitch", id: 3, position: null },
    { name: "Off Pitch", id: 4, position: null },
    { name: "Off Pitch", id: 5, position: null },
    { name: "Off Pitch", id: 6, position: null },
  ];

  const weeklyTeamDistance = (() => {
    let data = [...weeklyData];
    weekTeamDataStats.map((event) => {
      const dayNum = new Date(event.event.date?.seconds * 1000).getDay() - 1;
      const day = dayNum === -1 ? 6 : dayNum;
      data[day] = roundNumber(
        event.stats.team.fullGame.stats.Distance.distance
      );
    });
    return data;
  })();

  const weeklyTeamIntensity = (() => {
    let data = [...weeklyData];
    weekTeamDataStats.map((event) => {
      const dayNum = new Date(event.event.date?.seconds * 1000).getDay() - 1;
      const day = dayNum === -1 ? 6 : dayNum;
      data[day] = roundNumber(
        event.stats.team.fullGame.stats.Intensity?.amount
      );
    });
    return data;
  })();

  const weeklyTeamSprints = (() => {
    let data = [...weeklyData];
    weekTeamDataStats.map((event) => {
      const dayNum = new Date(event.event.date?.seconds * 1000).getDay() - 1;
      const day = dayNum === -1 ? 6 : dayNum;
      data[day] = roundNumber(
        event.stats.team.fullGame.stats.Normal?.sprinting?.distance
      );
    });
    return data;
  })();

  const weeklyTeamHIRunning = (() => {
    let data = [...weeklyData];
    weekTeamDataStats.map((event) => {
      const dayNum = new Date(event.event.date?.seconds * 1000).getDay() - 1;
      const day = dayNum === -1 ? 6 : dayNum;
      data[day] = roundNumber(
        event.stats.team.fullGame.stats.Normal?.hiRunning?.distance
      );
    });
    return data;
  })();

  const weeklyTeamActiveTime = (() => {
    let data = [...weeklyData];
    weekTeamDataStats.map((event) => {
      const dayNum = new Date(event.event.date?.seconds * 1000).getDay() - 1;
      const day = dayNum === -1 ? 6 : dayNum;
      data[day] = roundNumber(
        (event.event && event.event.status && event.event.status.totalTime
          ? event.event.status.totalTime
          : 0) / 60000
      );
    });
    return data;
  })();

  // Weekly Player Statistics

  const weeklyPlayersData = (() => {
    let rawStats = {};
    let stats = [];
    // example id: {name: "x y", stats: []}
    let comparisonEvents = [];
    let comparisonStats = [];
    let names = [];
    let intensity = [];
    let comparisonIntensity = [];
    let teamAvgIntensity = [];
    let distance = [];
    let comparisonDistance = [];
    let teamAvgDistance = [];
    let sprints = [];
    let comparisonSprints = [];
    let teamAvgSprints;
    let hiRunning = [];
    let comparisonhHIRunning = [];
    let teamAvgHIRunning;
    let activeTime = [];
    let comparisonActiveTime = [];
    let teamAvgActiveTime = [];
    let selectedPlayerStats = {
      intensity: [],
      comparisonIntensity: [],
      distance: [],
      comparisonDistance: [],
      activeTime: [],
      comparisonActiveTime: [],
      sprints: [],
      comparisonSprints: [],
      hiRunning: [],
      comparisonhHIRunning: [],
      eventName: [],
    };

    // players array with names
    // here we will show the players avg throughout this week on a name based chart
    if (weekTeamDataStats.length > 0) {
      weekTeamDataStats.map((event) => {
        let gamePlayers = event.event && event.stats && event.stats.players;

        // organize the players
        Object.entries(gamePlayers).map((p) => {
          let playerId = p[0];
          let playerStats = p[1].fullGame.stats;
          let activeTime = 0;
          if (
            event.event &&
            event.event.status &&
            event.event.status.totalTime
          ) {
            activeTime = roundNumber(event.event.status.totalTime / 60000);
          }
          playerStats["ActiveTime"] = activeTime;
          selectedPlayerStats.activeTime = activeTime;

          if (rawStats[playerId]) {
            rawStats[playerId].stats.push(playerStats);
          } else {
            const currentPlayer = players.data.find(
              (player) => player.id === playerId
            );
            const playerName = currentPlayer.name;
            rawStats[playerId] = { name: playerName, stats: [playerStats] };
          }
        });

        // start calculations
        if (Object.entries(rawStats).length > 0) {
          let activeTimeArray = [];
          let intensityArray = [];
          let distanceArray = [];
          let sprintArray = [];
          let hiRunningArray = [];
          let activeTimeCalculated = 0;
          let intensityCalculated = 0;
          let distanceCalculated = 0;
          let sprintCalculated = 0;
          let hiRunningCalculated;

          Object.entries(rawStats).map((playerEntry) => {
            let id = playerEntry[0];
            let playerData = playerEntry[1];
            names.push({ id: id, name: playerData.name });
            if (playerData.stats.length > 0) {
              // full the array to prepare calculation
              playerData.stats.map((stat) => {
                activeTimeArray.push(stat.ActiveTime);
                intensityArray.push(stat.Intensity.amount);
                distanceArray.push(stat.Distance.distance);
                sprintArray.push(stat.Normal.sprinting.distance);
                hiRunningArray.push(stat.Normal.hiRunning.distance);
              });
            }

            // perform calculations
            activeTimeCalculated = Math.round(
              activeTimeArray.reduce((a, b) => a + b, 0) /
                activeTimeArray.length
            );
            intensityCalculated = Math.round(
              intensityArray.reduce((a, b) => a + b, 0) / intensityArray.length
            );
            distanceCalculated = Math.round(
              distanceArray.reduce((a, b) => a + b, 0) / distanceArray.length
            );
            sprintCalculated = Math.round(
              sprintArray.reduce((a, b) => a + b, 0) / sprintArray.length
            );
            hiRunningCalculated = Math.round(
              hiRunningArray.reduce((a, b) => a + b, 0) / hiRunningArray.length
            );

            // push calculations
            intensity.push(intensityCalculated);
            distance.push(distanceCalculated);
            sprints.push(sprintCalculated);
            hiRunning.push(hiRunningCalculated);
            activeTime.push(activeTimeCalculated);
          });
        }
      });
    }
    // here we want to select specific player and see the performance, spead out throughout the week on a weekly chart

    if (selectedPlayer.id) {
      console.log("weekTeamDataStats", weekTeamDataStats);
      let playerIntensity = [...weeklyData];
      let playerDistance = [...weeklyData];
      let playerSprints = [...weeklyData];
      let playerHiRunning = [...weeklyData];
      weekTeamDataStats.map((event) => {
        const dayNum = new Date(event.event.date?.seconds * 1000).getDay() - 1;
        console.log("THIS IS THE EVENT", event);
        const day = dayNum === -1 ? 6 : dayNum;
        const players = event.stats.players;
        if (players && players[selectedPlayer.id]) {
          const selectedPlayerStats = players[selectedPlayer.id].fullGame.stats;
          console.log("HE IS HERE", selectedPlayerStats);
          playerIntensity[day] = roundNumber(
            selectedPlayerStats.Intensity.amount
          );
          playerDistance[day] = roundNumber(
            selectedPlayerStats.Distance.distance
          );
          playerSprints[day] = roundNumber(
            selectedPlayerStats.Normal.sprinting.distance
          );
          playerHiRunning[day] = roundNumber(
            selectedPlayerStats.Normal.hiRunning.distance
          );
        }
      });

      if (selectedPlayer.id) {
        selectedPlayerStats.intensity = playerIntensity;
        selectedPlayerStats.distance = playerDistance;
        selectedPlayerStats.sprints = playerSprints;
        selectedPlayerStats.hiRunning = playerHiRunning;
      }
    }

    return {
      names,
      activeTime,
      intensity,
      distance,
      sprints,
      hiRunning,
      selectedPlayerStats,
    };
  })();

  const totalAvgTeamSameEventDistance = (() => {
    let data = [...emptyWeeklyArray];
    weekTeamDataStats.map((event) => {
      const dayNum = new Date(event.event.date?.seconds * 1000).getDay() - 1;
      const day = dayNum === -1 ? 6 : dayNum;
      if (event.event.type === "match") {
        let eventsMatch = teamDataStats.filter(
          (event) => event.event.type === "match"
        );
        let avg =
          eventsMatch.reduce(
            (a, b) => a + b.stats?.team.fullGame.stats.Distance.distance,
            0
          ) / eventsMatch.length;
        data[day] = Math.round(avg);
      } else {
        let eventPosition = event.position;
        if (eventPosition) {
          let eventsWithSamePosition = teamDataStats.filter(
            (event) => event.position === eventPosition
          );
          let avg =
            eventsWithSamePosition.reduce(
              (a, b) => a + b.stats?.team.fullGame.stats.Distance.distance,
              0
            ) / eventsWithSamePosition.length;
          data[day] = Math.round(avg);
        }
      }
    });
    return data;
  })();

  const totalAvgTeamSameEventIntensity = (() => {
    let data = [...emptyWeeklyArray];
    weekTeamDataStats.map((event) => {
      const dayNum = new Date(event.event.date?.seconds * 1000).getDay() - 1;
      const day = dayNum === -1 ? 6 : dayNum;
      if (event.event.type === "match") {
        let eventsMatch = teamDataStats.filter(
          (event) => event.event.type === "match"
        );
        let avg =
          eventsMatch.reduce(
            (a, b) => a + b.stats?.team.fullGame.stats.Intensity?.amount,
            0
          ) / eventsMatch.length;
        data[day] = Math.round(avg);
      } else {
        let eventPosition = event.position;
        if (eventPosition) {
          let eventsWithSamePosition = teamDataStats.filter(
            (event) => event.position === eventPosition
          );
          let avg =
            eventsWithSamePosition.reduce(
              (a, b) => a + b.stats?.team.fullGame.stats.Intensity?.amount,
              0
            ) / eventsWithSamePosition.length;
          data[day] = Math.round(avg);
        }
      }
    });
    return data;
  })();

  const totalAvgTeamSameEventSprints = (() => {
    let data = [...emptyWeeklyArray];
    weekTeamDataStats.map((event) => {
      const dayNum = new Date(event.event.date?.seconds * 1000).getDay() - 1;
      const day = dayNum === -1 ? 6 : dayNum;
      if (event.event.type === "match") {
        let eventsMatch = teamDataStats.filter(
          (event) => event.event.type === "match"
        );
        let avg =
          eventsMatch.reduce(
            (a, b) =>
              a + b.stats?.team.fullGame.stats.Normal?.sprinting?.distance,
            0
          ) / eventsMatch.length;
        data[day] = Math.round(avg);
      } else {
        let eventPosition = event.position;
        if (eventPosition) {
          let eventsWithSamePosition = teamDataStats.filter(
            (event) => event.position === eventPosition
          );
          let avg =
            eventsWithSamePosition.reduce(
              (a, b) =>
                a + b.stats?.team.fullGame.stats.Normal?.sprinting?.distance,
              0
            ) / eventsWithSamePosition.length;
          data[day] = Math.round(avg);
        }
      }
    });
    return data;
  })();

  const totalAvgTeamSameEventHIRunning = (() => {
    let data = [...emptyWeeklyArray];
    weekTeamDataStats.map((event) => {
      const dayNum = new Date(event.event.date?.seconds * 1000).getDay() - 1;
      const day = dayNum === -1 ? 6 : dayNum;
      if (event.event.type === "match") {
        let eventsMatch = teamDataStats.filter(
          (event) => event.event.type === "match"
        );
        let avg =
          eventsMatch.reduce(
            (a, b) =>
              a + b.stats?.team.fullGame.stats.Normal?.hiRunning?.distance,
            0
          ) / eventsMatch.length;
        data[day] = Math.round(avg);
      } else {
        let eventPosition = event.position;
        if (eventPosition) {
          let eventsWithSamePosition = teamDataStats.filter(
            (event) => event.position === eventPosition
          );
          let avg =
            eventsWithSamePosition.reduce(
              (a, b) =>
                a + b.stats?.team.fullGame.stats.Normal?.hiRunning?.distance,
              0
            ) / eventsWithSamePosition.length;
          data[day] = Math.round(avg);
        }
      }
    });
    return data;
  })();

  const totalAvgTeamSameEventActiveTime = (() => {
    let data = [...emptyWeeklyArray];
    weekTeamDataStats.map((event) => {
      const dayNum = new Date(event.event.date?.seconds * 1000).getDay() - 1;
      const day = dayNum === -1 ? 6 : dayNum;
      if (event.event.type === "match") {
        let eventsMatch = teamDataStats.filter(
          (event) => event.event.type === "match"
        );
        let avg =
          eventsMatch.reduce(
            (a, b) => {
              let time =
                b.event && b.event.status && b.event.status.totalTime
                  ? b.event.status.totalTime
                  : 0;
              return a + time;
            },
            // a + b.stats?.team.fullGame.stats.Normal?.sprinting?.distance,
            0
          ) / eventsMatch.length;
        data[day] = Math.round(avg / 60000);
      } else {
        let eventPosition = event.position;
        if (eventPosition) {
          let eventsWithSamePosition = teamDataStats.filter(
            (event) => event.position === eventPosition
          );
          let avg =
            eventsWithSamePosition.reduce((a, b) => {
              let time =
                b.event && b.event.status && b.event.status.totalTime
                  ? b.event.status.totalTime
                  : 0;
              return a + time;
            }, 0) / eventsWithSamePosition.length;
          data[day] = Math.round(avg / 60000);
        }
      }
    });
    return data;
  })();

  const weeklyDescriptions = (() => {
    let data = [...weeklyDescriptionsArray];
    allWeekTeamDataStats.map((event) => {
      const dayNum = new Date(event.event.date?.seconds * 1000).getDay() - 1;
      const day = dayNum === -1 ? 6 : dayNum;
      if (event.position) {
        data[day].position = extractPositionOffset(event.position);
      }
      if (event.event.type === "training" && !event.position) {
        data[day].name = formatDateAndTime(
          new Date(event.event.date?.seconds * 1000)
        );
      }
      if (event.event.type === "match") {
        data[day].position = `${teamName} vs ${event.event.versus}`;
      }
    });
    return data;
  })();

  // Event Player Statistics

  const eventPlayersStats = (() => {
    let stats = [];
    let comparisonEvents = [];
    let comparisonStats = [];
    let names = [];
    let intensity = [];
    let comparisonIntensity = [];
    let teamAvgIntensity = [];
    let distance = [];
    let comparisonDistance = [];
    let teamAvgDistance = [];
    let sprints = [];
    let comparisonSprints = [];
    let teamAvgSprints;
    let hiRunning = [];
    let comparisonhHIRunning = [];
    let teamAvgHIRunning;
    let activeTime = [];
    let comparisonActiveTime = [];
    let teamAvgActiveTime = [];
    let selectedPlayerStats = {
      intensity: [],
      comparisonIntensity: [],
      distance: [],
      comparisonDistance: [],
      activeTime: [],
      comparisonActiveTime: [],
      sprints: [],
      comparisonSprints: [],
      hiRunning: [],
      comparisonhHIRunning: [],
      eventName: [],
    };
    let eventAvgActiveTime = 0;
    let eventAvgIntensity = 0;
    let eventAvgDistance = 0;
    let eventAvgSprint = 0;
    let eventAvgHIRunning = 0;
    if (selectedEvent) {
      comparisonEvents = teamDataStats.filter(
        (e) =>
          e.event.type === selectedEvent.event.type &&
          e.position === selectedEvent.position
      );
      let playersStats = Object.entries(selectedEvent.stats.players);

      if (playersStats.length > 0) {
        playersStats.map((stat) => {
          let player = players.data.find((p) => p.id === stat[0]);
          let playerComparisonGames = comparisonEvents
            .filter((ce) => {
              let pla = ce.stats.players[player.id];
              if (pla) {
                return pla;
              }
              return false;
            })
            .map((e) => e.stats.players[player.id].fullGame.stats);
          stats.push({
            stats: stat[1],
            player,
            comparisonStats: playerComparisonGames,
          });
        });
      }
    }
    if (stats.length > 0) {
      stats.map((stat) => {
        names.push({ name: stat.player.name, id: stat.player.id });
        if (stat.stats) {
          let fullgameStats = stat.stats.fullGame.stats;
          intensity.push(roundNumber(fullgameStats.Intensity.amount));
          distance.push(roundNumber(fullgameStats.Distance.distance));
          sprints.push(roundNumber(fullgameStats.Normal.sprinting.amount));
          hiRunning.push(roundNumber(fullgameStats.Normal.hiRunning.amount));
          if (stat.comparisonStats.length > 0) {
            comparisonIntensity.push(
              roundNumber(
                stat.comparisonStats.reduce(
                  (a, b) => a + b.Intensity.amount,
                  0
                ) / stat.comparisonStats.length
              )
            );
            comparisonDistance.push(
              roundNumber(
                stat.comparisonStats.reduce(
                  (a, b) => a + b.Distance.distance,
                  0
                ) / stat.comparisonStats.length
              )
            );
            comparisonSprints.push(
              roundNumber(
                stat.comparisonStats.reduce(
                  (a, b) => a + b.Normal.sprinting.amount,
                  0
                ) / stat.comparisonStats.length
              )
            );
            comparisonhHIRunning.push(
              roundNumber(
                stat.comparisonStats.reduce(
                  (a, b) => a + b.Normal.hiRunning.amount,
                  0
                ) / stat.comparisonStats.length
              )
            );
          }
          if (
            selectedEvent.event.status &&
            selectedEvent.event.status.totalTime
          ) {
            activeTime.push(
              roundNumber(selectedEvent.event.status.totalTime / 60000)
            );
            let timedComparisonEvents = comparisonEvents.filter(
              (e) => e.event.status && e.event.status.totalTime
            );

            comparisonActiveTime.push(
              roundNumber(
                timedComparisonEvents.reduce(
                  (a, b) => a + b.event.status.totalTime / 60000,
                  0
                ) / comparisonEvents.length
              )
            );
          }
        } else {
          intensity.push(0);
          distance.push(0);
          sprints.push(0);
          hiRunning.push(0);
        }
      });
      eventAvgActiveTime =
        roundNumber(
          activeTime.reduce((a, b) => a + b, 0) / activeTime.length
        ) || 0;
      eventAvgIntensity = roundNumber(
        intensity.reduce((a, b) => a + b, 0) / intensity.length
      );
      eventAvgDistance = roundNumber(
        distance.reduce((a, b) => a + b, 0) / distance.length
      );
      eventAvgSprint = roundNumber(
        sprints.reduce((a, b) => a + b, 0) / sprints.length
      );
      eventAvgHIRunning = roundNumber(
        hiRunning.reduce((a, b) => a + b, 0) / hiRunning.length
      );
      if (selectedPlayer.id) {
        let player = stats.find((stat) => stat.player.id === selectedPlayer.id);
        let playerIndex = names.findIndex((p) => p.id === selectedPlayer.id);

        if (player.stats) {
          selectedPlayerStats.intensity.push(intensity[playerIndex]);
          selectedPlayerStats.comparisonIntensity.push(
            comparisonIntensity[playerIndex]
          );
          selectedPlayerStats.distance.push(distance[playerIndex]);
          selectedPlayerStats.comparisonDistance.push(
            comparisonDistance[playerIndex]
          );
          selectedPlayerStats.sprints.push(sprints[playerIndex]);
          selectedPlayerStats.comparisonSprints.push(
            comparisonStats[playerIndex]
          );
          selectedPlayerStats.hiRunning.push(hiRunning[playerIndex]);
          selectedPlayerStats.comparisonhHIRunning.push(
            comparisonStats[playerIndex]
          );
          selectedPlayerStats.eventName.push({
            name: selectedEvent.displayName,
          });
          if (
            selectedEvent.event.status &&
            selectedEvent.event.status.totalTime
          ) {
            selectedPlayerStats.eventName.push({
              name: selectedEvent.displayName,
            });
            selectedPlayerStats.activeTime.push(
              roundNumber(activeTime[playerIndex])
            );
            selectedPlayerStats.comparisonActiveTime.push(
              comparisonActiveTime[playerIndex]
            );
          }
        }
      }
    }

    const avgIntensity = roundNumber(
      intensity.reduce((a, b) => a + b, 0) / intensity.length
    );
    const avgDistance = roundNumber(
      distance.reduce((a, b) => a + b, 0) / distance.length
    );
    const avgSprints = roundNumber(
      sprints.reduce((a, b) => a + b, 0) / sprints.length
    );
    const avgHIRunning = roundNumber(
      hiRunning.reduce((a, b) => a + b, 0) / hiRunning.length
    );
    const avgActiveTime = roundNumber(
      activeTime.reduce((a, b) => a + b, 0) / activeTime.length
    );

    const avgComparisonIntensity =
      comparisonIntensity.reduce((a, b) => a + b, 0) /
      comparisonIntensity.length;
    const avgComparisonDistance =
      comparisonDistance.reduce((a, b) => a + b, 0) / comparisonDistance.length;
    const avgComparisonSprints =
      comparisonSprints.reduce((a, b) => a + b, 0) / comparisonSprints.length;
    const avgComparisonHIRunning =
      comparisonhHIRunning.reduce((a, b) => a + b, 0) /
      comparisonhHIRunning.length;
    const avgComparisonActiveTime =
      comparisonActiveTime.reduce((a, b) => a + b, 0) /
      comparisonActiveTime.length;

    teamAvgIntensity = new Array(intensity.length).fill(avgComparisonIntensity);
    teamAvgDistance = new Array(distance.length).fill(avgComparisonDistance);
    teamAvgSprints = new Array(sprints.length).fill(avgComparisonSprints);
    teamAvgHIRunning = new Array(hiRunning.length).fill(avgComparisonHIRunning);
    teamAvgActiveTime = new Array(activeTime.length).fill(
      avgComparisonActiveTime
    );

    return {
      stats,
      names,
      intensity,
      sprints,
      hiRunning,
      distance,
      comparisonIntensity,
      comparisonSprints,
      comparisonhHIRunning,
      comparisonDistance,
      comparisonActiveTime,
      selectedPlayerStats,
      activeTime,
      eventAvgActiveTime,
      eventAvgIntensity,
      eventAvgDistance,
      eventAvgSprint,
      eventAvgHIRunning,
      teamAvgIntensity,
      teamAvgDistance,
      teamAvgSprints,
      teamAvgHIRunning,
      teamAvgActiveTime,
    };
  })();

  // Selected Player Stats

  return {
    avgTeamDistanceTotal,
    avgTeamIntensityTotal,
    avgTeamSprintsTotal,
    avgTeamActiveTimeTotal,
    avgTeamDistanceWeek,
    avgTeamIntensityWeek,
    avgTeamSprintsWeek,
    avgTeamHIRunningWeek,
    avgTeamActiveTimeWeek,
    weeklyTeamDistance,
    weeklyTeamIntensity,
    weeklyTeamSprints,
    weeklyTeamHIRunning,
    weeklyTeamActiveTime,
    weeklyDescriptions,
    weeklyPlayersData,
    totalAvgTeamSameEventDistance,
    totalAvgTeamSameEventIntensity,
    totalAvgTeamSameEventSprints,
    totalAvgTeamSameEventHIRunning,
    totalAvgTeamSameEventActiveTime,
    teamDataStats,
    eventPlayersStats,
  };
}
