import {
  FETCHING_PLAYERS,
  FETCHED_PLAYERS_SUCCESS,
  FETCHED_PLAYERS_FAILURE,
} from "../constants";

const initialState = {
  data: [],
  isFetching: false,
  fetched: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_PLAYERS:
      return { data: [], isFetching: true, fetched: false };
    case FETCHED_PLAYERS_SUCCESS:
      return { data: action.payload, isFetching: false, fetched: true };
    case FETCHED_PLAYERS_FAILURE:
      return {
        data: [],
        isFetching: false,
        fetched: true,
        error: action.payload,
      };
    default:
      return state;
  }
};
