import { Routes, Route } from "react-router-dom";
import DataScreen from "../pages/DataScreen";
import TeamSelection from "../pages/TeamSelection";

function PrivateRoutes() {
  return (
    <Routes>
      <Route path="/teamSelection" element={<TeamSelection />} />
      <Route path="/home" element={<DataScreen />} />
    </Routes>
  );
}

export default PrivateRoutes;
