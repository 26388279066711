import React from "react";

const Toggle = ({ state, setState }) => {
  return (
    <div className="toggle-btn">
      <div className="toggle-label">
        <p className="paragraph__red">Team Avg</p>
      </div>
      <label className="switch">
        <input type="checkbox" onClick={() => setState(!state)} />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default Toggle;
