export const cacheUser = (uid) => {
  localStorage.setItem("uid", JSON.stringify({ uid }));
};

export const uncacheUser = () => {
  localStorage.removeItem("uid");
};

export const getCacheUser = () => {
  try {
    return JSON.parse(localStorage.getItem("uid"));
  } catch (e) {
    return console.error(e);
  }
};

export const cacheClub = (club) => {
  localStorage.setItem("club", JSON.stringify(club));
};

export const uncacheClub = () => {
  localStorage.removeItem("club");
};

export const getCacheClub = () => {
  try {
    return JSON.parse(localStorage.getItem("club"));
  } catch (e) {
    return console.error(e);
  }
};
export const cacheTeam = (team) => {
  localStorage.setItem("team", JSON.stringify(team));
};

export const uncacheTeam = () => {
  localStorage.removeItem("team");
};

export const getCacheTeam = () => {
  try {
    return JSON.parse(localStorage.getItem("team"));
  } catch (e) {
    return console.error(e);
  }
};
