import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Toggle from "../Toggle";

const Highchart = ({
  name,
  dataCategories,
  secondaryData,
  lineData,
  primaryData,
  showAvg = false,
}) => {
  const [showTeamAvg, setShowTeamAvg] = useState(false);
  let chartSeries = [
    // {
    //   type: "column",
    //   name: "This Week",
    //   data: data,
    //   color: "#E5004D",
    // },
    // {
    //   type: "pie",
    //   name: "Total consumption",
    //   data: [
    //     {
    //       name: "Jane",
    //       y: 13,
    //       color: Highcharts.getOptions().colors[0], // Jane's color
    //     },
    //     {
    //       name: "John",
    //       y: 23,
    //       color: Highcharts.getOptions().colors[1], // John's color
    //     },
    //     {
    //       name: "Joe",
    //       y: 19,
    //       color: Highcharts.getOptions().colors[2], // Joe's color
    //     },
    //   ],
    //   center: [100, 80],
    //   size: 100,
    //   showInLegend: false,
    //   dataLabels: {
    //     enabled: false,
    //   },
    // },
  ];

  if (primaryData && primaryData.length > 0) {
    chartSeries = [
      ...chartSeries,
      {
        type: "column",
        name: name,
        data: primaryData,
        color: "#E5004D",
      },
    ];
  }

  if (secondaryData && secondaryData.length > 0) {
    chartSeries = [
      ...chartSeries,
      {
        type: "column",
        name: `Avg ${name.toLowerCase()} on the same +/- days or matchdays`,
        data: secondaryData,
        color: "#6AC4F8",
      },
    ];
  }

  if (showTeamAvg && lineData && lineData.length > 0) {
    chartSeries = [
      ...chartSeries,
      {
        type: "spline",
        name: "Team Average",
        data: lineData,
        marker: {
          lineWidth: 2,
          lineColor: Highcharts.getOptions().colors[3],
          fillColor: "white",
        },
      },
    ];
  }

  const chartData = {
    title: {
      text: name,
    },
    xAxis: {
      // categories: [
      //   "Monday",
      //   "Tuesday",
      //   "Wednesday",
      //   "Thursday",
      //   "Friday",
      //   "Saturday",
      //   "Sunday",
      // ],
      categories: dataCategories?.map((i) => {
        if (i.position) {
          return `${i.position}`;
        } else {
          return i.name;
        }
      }),
    },
    labels: {
      items: [
        {
          // html: name,
          style: {
            left: "50px",
            top: "18px",
            color: (Highcharts.theme && Highcharts.theme.textColor) || "black",
          },
        },
      ],
    },
    series: chartSeries,
  };

  return (
    <div>
      {showAvg && <Toggle state={showTeamAvg} setState={setShowTeamAvg} />}

      <HighchartsReact
        highcharts={Highcharts}
        options={chartData}
        // constructorType={"stockChart"}
      />
    </div>
  );
};

export default Highchart;
