import { combineReducers } from "redux";
import user from "./reducer_user";
import events from "./reducer_events";
import players from "./reducer_players";
import { LOG_OUT } from "../constants";

const reducers = {
  user,
  events,
  players,
};
const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === LOG_OUT) state = {};

  return appReducer(state, action);
};
export default rootReducer;
// export default combineReducers({
//   user,
//   events,
//   players,
// });
