// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import {
  getFirestore,
  collection,
  getDocs,
  Timestamp,
  doc,
  getDoc,
  query,
} from "firebase/firestore";
import moment from "moment";
import firebaseConfigEnv from "./firebase_config.json";
import {
  FETCHED_EVENTS_SUCCESS,
  FETCHED_PLAYERS_SUCCESS,
  LOG_OUT,
} from "../constants";
import {
  cacheClub,
  getCacheClub,
  getCacheTeam,
  uncacheClub,
  uncacheTeam,
} from "../hooks/useLocalStorage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig =
  process.env.REACT_APP_ENV === "production"
    ? firebaseConfigEnv.prod
    : firebaseConfigEnv.dev;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export const loginWithEmailAndPassword = async (email, password) => {
  return await signInWithEmailAndPassword(auth, email, password)
    .then((resp) => {
      const user = resp.user;
      return user.uid;
    })
    .catch((err) => {
      alert(`ERROR LOGIN: ${err.message} `);
      return false;
    });
};

export async function getClubByUser(uid) {
  const usersRef = doc(db, "users", uid);
  const userInfo = await getDoc(usersRef);

  if (userInfo.exists()) {
    cacheClub(userInfo.data().customerName);
    let teams = await getClub(userInfo.data().customerName);
    return teams;
  } else {
    alert("No such document!");
  }
}

export async function getClub(club) {
  let teams = [];
  let clubName = (await getCacheClub()) || club;
  const clubInfo = await getDocs(collection(db, clubName));
  clubInfo.forEach((doc) => {
    teams.push({
      id: doc.id,
      data: doc.data(),
    });
  });
  return teams;
}

export const logout = () => async (dispatch) => {
  dispatch({ type: LOG_OUT });
  uncacheTeam();
  uncacheClub();
  await signOut(auth);
  window.location.reload();
};

export const getEvents = () => async (dispatch) => {
  let games = [];
  let gamesInfo = [];
  const club = await getCacheClub();
  const team = await getCacheTeam();
  const gamesRef = collection(db, `${club}/${team.id}/games`);
  const q = query(gamesRef);
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach(async (game) => {
    const statRef = doc(db, club, team.id, "games", game.id, "report", "stats");
    const seriesRef = doc(
      db,
      club,
      team.id,
      "games",
      game.id,
      "report",
      "series"
    );
    const statSnap = await getDoc(statRef);
    const seriesSnap = await getDoc(seriesRef);
    games = [
      ...games,
      {
        event: game.data(),
        stats: statSnap.data(),
        series: seriesSnap.data(),
        position: null,
      },
    ];
    dispatch({ type: FETCHED_EVENTS_SUCCESS, payload: games });
  });
  return { games, gamesInfo };
};

export const parseDateToFirestore = (date) => {
  return Timestamp.fromDate(new Date(date));
};

export const getTeamPlayers = () => async (dispatch) => {
  let players = [];
  const club = await getCacheClub();
  const team = await getCacheTeam();
  const gamesRef = collection(db, `${club}/${team.id}/players`);
  const q = query(gamesRef);
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach(async (p) => {
    const player = p.data();
    players = [...players, player];
    dispatch({ type: FETCHED_PLAYERS_SUCCESS, payload: players });
  });
};

export { auth, db };
