import React, { useState, useEffect } from "react";
import Dropdown from "../../components/common/Dropdown";
import { getCacheClub, getCacheTeam } from "../../hooks/useLocalStorage";
import { getEvents, getTeamPlayers } from "../../store/firebase";
import { getCurrentWeek, weeksInYear } from "../../helpers/dateTimeHelpers";
import { useDispatch, useSelector } from "react-redux";
import { calculateTeamAvgStats } from "../../calculators/statsCalculators";
import Highchart from "../../components/Highchart/index";
import {
  reportTypes,
  sessionDataSetType,
  weeklyDataSetType,
} from "../../constants/index";

const DataScreen = ({}) => {
  const events = useSelector((state) => state.events);
  const players = useSelector((state) => state.players);
  const dispatch = useDispatch();
  const team = getCacheTeam();
  const club = getCacheClub();
  const weeks = weeksInYear();

  const [selectedWeek, setSelectedWeek] = useState(getCurrentWeek());
  const [reportType, setReportType] = useState(reportTypes[0].id);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedUserType, setSelectedUserType] = useState("Events");
  const [selectedPlayer, setSelectedPlayer] = useState({
    id: null,
    name: null,
  });
  const eventsReport = calculateTeamAvgStats({
    allEvents: events,
    selectedWeek,
    teamName: team.data.name,
    players: players,
    selectedEvent,
    selectedPlayer,
  });

  // avgTeamHIRunningWeek
  // weeklyTeamHIRunning
  // totalAvgTeamSameEventHIRunning
  // avgComparisonHIRunning

  /* 
    let hiRunning = [];
    let comparisonhHIRunning = [];
    let teamAvgHIRunning;
  */
  /*
  selectedPlayerStats 
      hiRunning: [],
      comparisonhHIRunning: [],
  */

  useEffect(() => {
    if (events.data.length === 0) {
      dispatch(getEvents());
      dispatch(getTeamPlayers());
    }
  }, []);

  let renderDropdowns = () => {
    return (
      <div className="data-screen__header_main__dropdowns-wrapper">
        <Dropdown
          value={reportType}
          size={"s"}
          options={reportTypes.map(({ name, id }) => {
            return { id, name };
          })}
          handleChange={(e) => {
            if (e.toLowerCase() === "weekly report") {
              setSelectedUserType("Events");
            } else {
              setSelectedUserType("Team");
            }
            setSelectedPlayer({ id: null, name: null });
            setReportType(e);
          }}
        />
        {reportType.toLowerCase() === "weekly report" && (
          <Dropdown
            value={selectedWeek.id}
            size={"s"}
            options={weeks.weekArr.map(({ name, id }) => {
              return { id, name };
            })}
            handleChange={(e) => {
              let weekSelected = weeks.weekArr.find(
                (w) => w.id === parseInt(e)
              );
              setSelectedWeek(weekSelected);
              setSelectedUserType("Events");
              setSelectedPlayer({
                id: null,
                name: null,
              });
            }}
          />
        )}
        {reportType.toLowerCase() === "weekly report" &&
          eventsReport.weeklyPlayersData && (
            <Dropdown
              value={selectedUserType}
              size={"s"}
              options={weeklyDataSetType.map(({ name, id }) => {
                return { id, name };
              })}
              handleChange={(e) => {
                setSelectedUserType(e);
                e.toLowerCase() === "player" &&
                  eventsReport.weeklyPlayersData.names.length > 0 &&
                  setSelectedPlayer(eventsReport.weeklyPlayersData.names[0]);
              }}
              disabled={eventsReport.weeklyPlayersData.names.length === 0}
            />
          )}
        {reportType.toLowerCase() === "weekly report" &&
          selectedUserType === "Player" &&
          eventsReport.weeklyPlayersData && (
            <Dropdown
              value={selectedPlayer.name}
              size={"s"}
              options={
                eventsReport.weeklyPlayersData.names.map((player) => {
                  return { name: player.name, id: player.id };
                }) || []
              }
              handleChange={(e) => {
                let player = eventsReport.weeklyPlayersData.names.find(
                  (p) => p.id === e
                );
                setSelectedPlayer(player);
              }}
            />
          )}
        {reportType.toLowerCase() === "session" && (
          <Dropdown
            value={selectedEvent ? selectedEvent.event.docId : null}
            size={"s"}
            options={
              eventsReport.teamDataStats.map((event) => {
                return { name: event.displayName, id: event.event.docId };
              }) || []
            }
            handleChange={(e) => {
              let eventSelect = eventsReport.teamDataStats.find(
                (i) => i.event.docId === e
              );
              setSelectedUserType("Team");
              setSelectedPlayer({ id: null, name: null });
              setSelectedEvent(eventSelect);
            }}
          />
        )}
        {reportType.toLowerCase() === "session" && (
          <Dropdown
            value={selectedUserType}
            size={"s"}
            options={sessionDataSetType.map(({ name, id }) => {
              return { id, name };
            })}
            handleChange={(e) => {
              setSelectedUserType(e);
              e.toLowerCase() === "player" &&
                setSelectedPlayer(eventsReport.eventPlayersStats.names[0]);
            }}
            disabled={eventsReport.eventPlayersStats.names.length === 0}
          />
        )}
        {reportType.toLowerCase() === "session" &&
          selectedUserType === "Player" && (
            <Dropdown
              value={selectedPlayer.name}
              size={"s"}
              options={
                eventsReport.eventPlayersStats.names.map((player) => {
                  return { name: player.name, id: player.id };
                }) || []
              }
              handleChange={(e) => {
                let player = eventsReport.eventPlayersStats.names.find(
                  (p) => p.id === e
                );
                setSelectedPlayer(player);
              }}
            />
          )}
      </div>
    );
  };

  let renderCharts = () => {
    if (reportType.toLowerCase() === "weekly report") {
      if (selectedUserType === "Events") {
        return (
          <>
            <Highchart
              name="Minutes played"
              primaryData={eventsReport.weeklyTeamActiveTime}
              secondaryData={eventsReport.totalAvgTeamSameEventActiveTime}
              lineData={[]}
              dataCategories={eventsReport.weeklyDescriptions}
            />
            <Highchart
              name="Player Load"
              primaryData={eventsReport.weeklyTeamIntensity}
              secondaryData={eventsReport.totalAvgTeamSameEventIntensity}
              lineData={[]}
              dataCategories={eventsReport.weeklyDescriptions}
            />
            <Highchart
              name="Total Distance (m)"
              primaryData={eventsReport.weeklyTeamDistance}
              secondaryData={eventsReport.totalAvgTeamSameEventDistance}
              lineData={[]}
              dataCategories={eventsReport.weeklyDescriptions}
            />
            <Highchart
              name="Sprints distance (m)"
              primaryData={eventsReport.weeklyTeamSprints}
              secondaryData={eventsReport.totalAvgTeamSameEventSprints}
              lineData={[]}
              dataCategories={eventsReport.weeklyDescriptions}
            />
            <Highchart
              name="HI running distance (m)"
              primaryData={eventsReport.weeklyTeamHIRunning}
              secondaryData={eventsReport.totalAvgTeamSameEventHIRunning}
              lineData={[]}
              dataCategories={eventsReport.weeklyDescriptions}
            />
          </>
        );
      }
      if (selectedUserType === "Team") {
        return (
          <>
            <Highchart
              name="Minutes played"
              primaryData={eventsReport.weeklyPlayersData.activeTime}
              // secondaryData={eventsReport.totalAvgTeamSameEventActiveTime}
              lineData={[]}
              dataCategories={eventsReport.weeklyPlayersData.names}
            />
            <Highchart
              name="Player Load"
              primaryData={eventsReport.weeklyPlayersData.intensity}
              // secondaryData={eventsReport.totalAvgTeamSameEventIntensity}
              // lineData={[]}
              dataCategories={eventsReport.weeklyPlayersData.names}
            />
            <Highchart
              name="Total Distance (m)"
              primaryData={eventsReport.weeklyPlayersData.distance}
              // secondaryData={eventsReport.totalAvgTeamSameEventDistance}
              lineData={[]}
              dataCategories={eventsReport.weeklyPlayersData.names}
            />
            <Highchart
              name="Sprints distance (m)"
              primaryData={eventsReport.weeklyPlayersData.sprints}
              // secondaryData={eventsReport.totalAvgTeamSameEventSprints}
              lineData={[]}
              dataCategories={eventsReport.weeklyPlayersData.names}
            />
            <Highchart
              name="HI running distance (m)"
              primaryData={eventsReport.weeklyPlayersData.hiRunning}
              // secondaryData={eventsReport.totalAvgTeamSameEventHIRunning}
              lineData={[]}
              dataCategories={eventsReport.weeklyPlayersData.names}
            />
          </>
        );
      }
      if (selectedUserType === "Player" && selectedPlayer.id) {
        return (
          <>
            {/* <Highchart
              name="Minutes played"
              primaryData={
                eventsReport.weeklyPlayersData.selectedPlayerStats.activeTime
              }
              // secondaryData={eventsReport.totalAvgTeamSameEventActiveTime}
              lineData={[]}
              // dataCategories={eventsReport.weeklyPlayersData.names}
            /> */}
            <Highchart
              name="Player Load"
              primaryData={
                eventsReport.weeklyPlayersData.selectedPlayerStats.intensity
              }
              // secondaryData={eventsReport.totalAvgTeamSameEventIntensity}
              // lineData={[]}
              dataCategories={eventsReport.weeklyDescriptions}
            />
            <Highchart
              name="Total Distance (m)"
              primaryData={
                eventsReport.weeklyPlayersData.selectedPlayerStats.distance
              }
              // secondaryData={eventsReport.totalAvgTeamSameEventDistance}
              lineData={[]}
              dataCategories={eventsReport.weeklyDescriptions}
            />
            <Highchart
              name="Sprints distance (m)"
              primaryData={
                eventsReport.weeklyPlayersData.selectedPlayerStats.sprints
              }
              // secondaryData={eventsReport.totalAvgTeamSameEventSprints}
              lineData={[]}
              dataCategories={eventsReport.weeklyDescriptions}
            />
            <Highchart
              name="HI running distance (m)"
              primaryData={
                eventsReport.weeklyPlayersData.selectedPlayerStats.hiRunning
              }
              // secondaryData={eventsReport.totalAvgTeamSameEventHIRunning}
              lineData={[]}
              dataCategories={eventsReport.weeklyDescriptions}
            />
          </>
        );
      }
    }
    if (reportType.toLowerCase() === "session") {
      if (selectedUserType === "Team") {
        return (
          <>
            {eventsReport.eventPlayersStats &&
              eventsReport.eventPlayersStats.activeTime.length > 0 && (
                <Highchart
                  name="Minutes Played"
                  primaryData={eventsReport.eventPlayersStats.activeTime}
                  secondaryData={
                    eventsReport.eventPlayersStats.comparisonActiveTime
                  }
                  lineData={eventsReport.eventPlayersStats.teamAvgActiveTime}
                  dataCategories={eventsReport.eventPlayersStats.names}
                  showAvg
                />
              )}

            <Highchart
              name="Player Load"
              primaryData={eventsReport.eventPlayersStats.intensity}
              secondaryData={eventsReport.eventPlayersStats.comparisonIntensity}
              lineData={eventsReport.eventPlayersStats.teamAvgIntensity}
              dataCategories={eventsReport.eventPlayersStats.names}
              showAvg
            />
            <Highchart
              name="Distance (m)"
              primaryData={eventsReport.eventPlayersStats.distance}
              secondaryData={eventsReport.eventPlayersStats.comparisonDistance}
              lineData={eventsReport.eventPlayersStats.teamAvgDistance}
              dataCategories={eventsReport.eventPlayersStats.names}
              showAvg
            />
            <Highchart
              name="Sprints (m)"
              primaryData={eventsReport.eventPlayersStats.sprints}
              secondaryData={eventsReport.eventPlayersStats.comparisonSprints}
              lineData={eventsReport.eventPlayersStats.teamAvgSprints}
              dataCategories={eventsReport.eventPlayersStats.names}
              showAvg
            />
            <Highchart
              name="HI running (m)"
              primaryData={eventsReport.eventPlayersStats.hiRunning}
              secondaryData={
                eventsReport.eventPlayersStats.comparisonhHIRunning
              }
              lineData={eventsReport.eventPlayersStats.teamAvgHIRunning}
              dataCategories={eventsReport.eventPlayersStats.names}
              showAvg
            />
          </>
        );
      }
      if (selectedUserType === "Player" && selectedPlayer.id) {
        return (
          <>
            {eventsReport.eventPlayersStats.selectedPlayerStats &&
              eventsReport.eventPlayersStats.selectedPlayerStats.activeTime
                .length > 0 && (
                <Highchart
                  name="Minutes Played"
                  primaryData={
                    eventsReport.eventPlayersStats.selectedPlayerStats
                      .activeTime
                  }
                  lineData={eventsReport.eventPlayersStats.teamAvgActiveTime}
                  secondaryData={
                    eventsReport.eventPlayersStats.selectedPlayerStats
                      .comparisonActiveTime
                  }
                  dataCategories={
                    eventsReport.eventPlayersStats.selectedPlayerStats.eventName
                  }
                />
              )}

            <Highchart
              name="Player Load"
              primaryData={
                eventsReport.eventPlayersStats.selectedPlayerStats.intensity
              }
              lineData={eventsReport.eventPlayersStats.teamAvgIntensity}
              secondaryData={
                eventsReport.eventPlayersStats.selectedPlayerStats
                  .comparisonIntensity
              }
              dataCategories={
                eventsReport.eventPlayersStats.selectedPlayerStats.eventName
              }
            />
            <Highchart
              name="Distance (m)"
              primaryData={
                eventsReport.eventPlayersStats.selectedPlayerStats.distance
              }
              lineData={eventsReport.eventPlayersStats.teamAvgDistance}
              secondaryData={
                eventsReport.eventPlayersStats.selectedPlayerStats
                  .comparisonDistance
              }
              dataCategories={
                eventsReport.eventPlayersStats.selectedPlayerStats.eventName
              }
            />
            <Highchart
              name="Sprints (m)"
              primaryData={
                eventsReport.eventPlayersStats.selectedPlayerStats.sprints
              }
              lineData={eventsReport.eventPlayersStats.teamAvgSprints}
              secondaryData={
                eventsReport.eventPlayersStats.selectedPlayerStats
                  .comparisonSprints
              }
              dataCategories={
                eventsReport.eventPlayersStats.selectedPlayerStats.eventName
              }
            />
            <Highchart
              name="HI running (m)"
              primaryData={
                eventsReport.eventPlayersStats.selectedPlayerStats.hiRunning
              }
              lineData={eventsReport.eventPlayersStats.teamAvgHIRunning}
              secondaryData={
                eventsReport.eventPlayersStats.selectedPlayerStats
                  .comparisonhHIRunning
              }
              dataCategories={
                eventsReport.eventPlayersStats.selectedPlayerStats.eventName
              }
            />
          </>
        );
      }
    }

    return <p>Select Option</p>;
  };

  const renderHeaders = () => {
    if (reportType.toLowerCase() === "weekly report") {
      return (
        <div className="data-screen__header_data">
          <div className="indicator-container">
            <p className="indicator-container__title paragraph__center">
              Avg. Active time [min]
            </p>
            <p className="indicator-container__data">
              {eventsReport.avgTeamActiveTimeWeek}
            </p>
          </div>
          <div className="indicator-container">
            <p className="indicator-container__title paragraph__center">
              Avg.Player Load
            </p>
            <p className="indicator-container__data">
              {eventsReport.avgTeamIntensityWeek}
            </p>
          </div>
          <div className="indicator-container">
            <div className="indicator-container__title">
              <p className="paragraph__center">Avg. Total Distance [m]</p>
            </div>
            <p className="indicator-container__data">
              {eventsReport.avgTeamDistanceWeek}
            </p>
          </div>
          <div className="indicator-container">
            <p className="indicator-container__title paragraph__center">
              Avg. Sprint Distance[m]
            </p>
            <p className="indicator-container__data">
              {eventsReport.avgTeamSprintsWeek}
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="data-screen__header_data">
          <div className="indicator-container">
            <p className="indicator-container__title paragraph__center">
              Avg. Active time [min]
            </p>
            <p className="indicator-container__data indicator-container__data__secodnary">
              {eventsReport.eventPlayersStats.eventAvgActiveTime}
            </p>
          </div>
          <div className="indicator-container">
            <p className="indicator-container__title paragraph__center">
              Avg. Accumulated Intensity Indication
            </p>
            <p className="indicator-container__data indicator-container__data__secodnary">
              {eventsReport.eventPlayersStats.eventAvgIntensity}
            </p>
          </div>
          <div className="indicator-container">
            <div className="indicator-container__title">
              <p className="paragraph__center">Avg. Total Distance [m]</p>
            </div>
            <p className="indicator-container__data indicator-container__data__secodnary">
              {eventsReport.eventPlayersStats.eventAvgDistance}
            </p>
          </div>
          <div className="indicator-container">
            <p className="indicator-container__title paragraph__center">
              Avg. Sprint Distance[m]
            </p>
            <p className="indicator-container__data indicator-container__data__secodnary ">
              {eventsReport.eventPlayersStats.eventAvgSprint}
            </p>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="screen">
      <div className="data-screen__header">
        <div className="data-screen__header_main">
          {renderDropdowns()}
          <div>
            <p className="paragraph paragraph__l paragraph__b">Club: {club}</p>
            <p className="paragraph paragraph__l paragraph__b">
              Team: {team.data?.name}
            </p>
          </div>
        </div>
        {renderHeaders()}
      </div>

      <div className="charts-container">{renderCharts()}</div>
    </div>
  );
};

export default DataScreen;
