import moment from "moment";

export const dayIndex = (now = new Date()) => {
  let start = new Date(now.getFullYear(), 0, 0);
  let diff =
    now -
    start +
    (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
  let oneDay = 1000 * 60 * 60 * 24;
  let day = Math.floor(diff / oneDay);
  return day - 1;
};
export const dayAddition = 1000 * 60 * 60 * 24;
export const weekAddition = dayAddition * 7;
export const startOfWeek = (timestamp = new Date().getTime()) =>
  moment(timestamp).startOf("week").toDate().getTime() + dayAddition;
export const endOfWeek = (timestamp = new Date().getTime()) =>
  moment(timestamp).endOf("week").toDate().getTime() + dayAddition;
export const currentWeek = getCurrentWeek();
export const startOfYear = moment().startOf("year").toDate().getTime();
export const endOfYear = moment().endOf("year").toDate().getTime();

export function getCurrentWeek(timestamp = new Date().getTime()) {
  let currentDate = new Date(timestamp);
  let startDate = new Date(currentDate.getFullYear(), 0, 1);
  let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
  let currentWeek = Math.ceil(days / 7);
  return {
    name: `Week ${currentWeek}`,
    id: currentWeek,
    startOfWeek: startOfWeek(timestamp),
    endOfWeek: endOfWeek(timestamp),
  };
}

function getWeekNumber(d) {
  // Copy date so don't modify original
  d = new Date(+d);
  d.setHours(0, 0, 0, 0);
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  // Get first day of year
  var yearStart = new Date(d.getFullYear(), 0, 1);
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  return [d.getFullYear(), weekNo];
}

export function weeksInYear(year = new Date().getFullYear()) {
  var month = 11,
    day = 31,
    week,
    d;
  let weekArr = [];
  do {
    d = new Date(year, month, day--);
    week = getWeekNumber(d)[1];
  } while (week == 1);
  for (let i = 0; i < week; i++) {
    let difference = i - currentWeek.id;
    let weekDifference = weekAddition * difference;
    let weekStart = startOfWeek() + weekDifference;
    let weekEnd = endOfWeek() + weekDifference;
    if (i <= currentWeek.id) {
      weekArr.push({
        name: `Week ${i} - ${moment(weekStart).format("ll")} -  ${moment(
          weekEnd
        ).format("ll")}`,
        id: i,
        startOfWeek: weekStart,
        endOfWeek: weekEnd,
      });
    }
  }
  return { weekArr, size: week };
}

export const yearDaysArray = new Array(365).fill();

export const formatFirebaseDateAndTime = (date) => {
  const format = "DD-MM-YYYY";
  let dateTime = moment(date * 1000).format(format);
  return dateTime;
};
export const formatDateAndTime = (date = new Date().getTime()) => {
  const format = "DD-MM-YYYY";
  let dateTime = moment(date).format(format);
  return dateTime;
};
